// Code for the App.js file
import React, { useState, useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [code, setCode] = useState("");
  const correctCode = "1712"; // Replace with your desired 4-digit code
  const [progress, setProgress] = useState(33);
  const [progressText, setProgressText] = useState(
    "Im Casting-Prozess von Lampenfieber reichen die Bands einen Song ein, der von einer Jury aus erfahrenen Musikexperten bewertet wird. Dabei stehen Musikalität, Kreativität und Originalität im Fokus. Die besten Einsendungen schaffen es in die nächste Runde, die Battles. Den Abschluss der Castings bildet ein besonderes Event, bei dem sich alle teilnehmenden Musiker näher kennenlernen und Feedback von der Jury erhalten können. Während des Abends wird jede halbe Stunde ein(e) Teilnehmer(in) bekannt gegeben, die in die Battles voranschreitet. So bleibt die Spannung bis zum Ende erhalten und bietet den Künstlern eine einzigartige Gelegenheit zum Austausch."
  );
  const [progressRound, setProgressRound] = useState("Runde 1");
  const [progressSymbol, setProgressSymbol] = useState("Ca");
  const [progressName, setProgressName] = useState("Casting");
  const [color, setColor] = useState("#d42cca");
  const colorPickerRef = useRef(null);

  useEffect(() => {
    const colorPicker = colorPickerRef.current;
    const handleColorChange = (event) => {
      const color = event.target.value;
      setColor(color);
      document.documentElement.style.setProperty("--neon-second", color);
    };

    if (colorPicker) {
      colorPicker.addEventListener("input", handleColorChange);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (colorPicker) {
        colorPicker.removeEventListener("input", handleColorChange);
      }
    };
  }, [isAuthenticated]);

  const handleScroll = (event) => {
    console.log("Scroll button clicked");
    const buttonPosition =
      event.target.getBoundingClientRect().top + window.scrollY;
    const scrollAmount = 100;
    window.scrollTo({
      top: buttonPosition + scrollAmount,
      behavior: "smooth",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code === correctCode) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect code. Please try again.");
    }
  };

  const handleProgressClick = (step) => {
    const progressValues = [33, 66, 100];
    const progressTexts = [
      "Im Casting-Prozess von Lampenfieber reichen die Bands einen Song ein, der von einer Jury aus erfahrenen Musikexperten bewertet wird. Dabei stehen Musikalität, Kreativität und Originalität im Fokus. Die besten Einsendungen schaffen es in die nächste Runde, die Battles. Den Abschluss der Castings bildet ein besonderes Event, bei dem sich alle teilnehmenden Musiker näher kennenlernen und Feedback von der Jury erhalten können. Während des Abends wird jede halbe Stunde ein(e) Teilnehmer(in) bekannt gegeben, die in die Battles voranschreitet. So bleibt die Spannung bis zum Ende erhalten und bietet den Künstlern eine einzigartige Gelegenheit zum Austausch.",
      "In der Battle-Phase von Lampenfieber treten jeweils fünf Bands gegeneinander an, jede mit 20 Minuten Spielzeit. Dabei gilt eine besondere Regel: Es dürfen ausschließlich eigene Songs gespielt werden. Die Bands haben also die Chance, mit ihrer Originalität und Kreativität zu punkten. Sowohl die Jury als auch das Publikum bewerten die Auftritte, wobei Musikalität, Bühnenpräsenz und Songwriting im Vordergrund stehen. Am Ende jedes Battles zieht nur ein Teilnehmer ins Finale ein, was die Konkurrenz besonders hart und spannend macht. Wer schafft es, die Menge und die Jury gleichermaßen zu überzeugen und sich einen Platz im Finale zu sichern? Da es 5 Finalisten, aber nur 4 Battles gibt, wird der 5. Finalist durch ein Wildcard-Voting auf den Sozialen Medien bestimmt.",
      "Im Finale von Lampenfieber treten die besten fünf Bands des Wettbewerbs gegeneinander an, jede mit einer 20-minütigen Performance, bei der ausschließlich eigene Songs gespielt werden. Neben der regulären Runde erwartet die Finalisten eine spannende Spezialrunde, die eine zusätzliche Herausforderung mitbringt. Sowohl die Jury als auch das Publikum bewerten die Auftritte, wobei neben Musikalität auch Kreativität und Bühnenpräsenz entscheidend sind. Am Ende winken Preise für die ersten drei Plätze sowie ein begehrter Publikumspreis. Wer wird sich den Sieg sichern und wer überzeugt das Publikum am meisten? Die Entscheidung verspricht ein packendes Finale voller Überraschungen!",
    ];
    const round = ["Runde 1", "Runde 2", "Runde 3"];
    const symbol = ["Ca", "Ba", "Fi"];
    const name = ["Casting", "Battle", "Finale"];
    setProgress(progressValues[step]);
    setProgressText(progressTexts[step]);
    setProgressRound(round[step]);
    setProgressSymbol(symbol[step]);
    setProgressName(name[step]);
  };

  return (
    <div className="App">
      {!isAuthenticated ? (
        <form onSubmit={handleSubmit} className="auth-container">
          <input
            className="authentication-input"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter code"
          />
          <button type="submit" className="submit-btn send">
            Submit
          </button>
        </form>
      ) : (
        <>
          <header className="App-header">
            <h1 className="neon" data-text="U">
              <span className="first-line">
                L<span className="flicker-slow">A</span>MP
                <span className="flicker-medium">E</span>
                <span className="flicker-slow">N</span>
              </span>
              <span className="second-line">
                FI<span className="flicker-fast">E</span>BE
                <span className="flicker-medium">R</span>
              </span>
            </h1>
          </header>
          <section className="scroll-button">
            <button className="scroll-down" onClick={handleScroll}>
              <div className="wrapper">
                <i className="zmdi zmdi-chevron-down"></i>
                <i className="zmdi zmdi-chevron-down"></i>
                <i className="zmdi zmdi-chevron-down"></i>
              </div>
            </button>
          </section>
          <section className="Color Picker">
            <h2>Color Picker</h2>
            <input
              type="color"
              id="colorPicker"
              name="colorPicker"
              ref={colorPickerRef}
              value={color}
              onChange={(e) => setColor(e.target.value)}
            ></input>
          </section>
          <section className="promo-video">
            <h2>Promotional Video</h2>
            <video controls width="1000">
              <source
                src="https://www.w3schools.com/html/mov_bbb.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </section>
          <section className="intro">
            <h2>Hast du Lampenfieber?</h2>
            <div className="columns">
              <div className="column">
                <p>
                  Willkommen zu "Lampenfieber" – dem ultimativen
                  Musikwettbewerb! Hier treffen talentierten Musiker und
                  Musikerinnen aufeinander, um zu zeigen, wer das Publikum zum
                  Beben bringt. Von Rock,Pop über HipHop bis hin zu Indie
                  erwartet euch ein musikalisches Feuerwerk, bei dem Talent,
                  Energie und Kreativität im Vordergrund stehen. Bei
                  "Lampenfieber" geht es um mehr als nur Musik – es geht um den
                  Moment, in dem sich alles entscheidet! Wer hat das Zeug, die
                  Menge zu begeistern und die Jury zu überzeugen? Wer wird am
                  Ende den Titel gewinnen und die Bühne als Sieger verlassen?
                  Spannung, Musik und jede Menge Talent warten auf euch!
                </p>
              </div>
              {/* <div className="column">
                  <p>Bei "Lampenfieber" geht es um mehr als nur Musik – es geht um den Moment, in dem sich alles entscheidet! Wer hat das Zeug, die Menge zu begeistern und die Jury zu überzeugen? Wer wird am Ende den Titel gewinnen und die Bühne als Sieger verlassen? Spannung, Musik und jede Menge Talent warten auf euch!</p>
                </div> */}
            </div>
          </section>
          <section className="progress-section">
            <h2>Progress Indicator</h2>
            <div className="progress-steps">
              <button
                className="submit-btn send"
                onClick={() => handleProgressClick(0)}
              >
                Casting
              </button>
              <button
                className="submit-btn send"
                onClick={() => handleProgressClick(1)}
              >
                Battle
              </button>
              <button
                className="submit-btn send"
                onClick={() => handleProgressClick(2)}
              >
                Finale
              </button>
            </div>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="progress-content">
              <div class="neon-card pink">
                <span class="round">{progressRound}</span>
                <h2 class="symbol">{progressSymbol}</h2>
                <p class="name">{progressName}</p>
              </div>
              <p className="progress-text">{progressText}</p>
            </div>
          </section>
          <section className="prizes">
            <h2>Preise</h2>
            <div className="neon-card silver">
              <h2 className="symbol">2. Platz</h2>
              <div className="prize-list">
                <ul>
                  <li>Preis 1</li>
                  <li>Preis 2</li>
                  <li>Preis 3</li>
                </ul>
              </div>
              <button
                type="submit"
                className="submit-btn send"
                onClick={handleScroll}
              >
                Anmelden
              </button>
            </div>
            <div className="neon-card gold">
              <h2 className="symbol">1. Platz</h2>
              <div className="prize-list">
                <ul>
                  <li>Preis 1</li>
                  <li>Preis 2</li>
                  <li>Preis 3</li>
                </ul>
              </div>
              <button
                type="submit"
                className="submit-btn send"
                onClick={handleScroll}
              >
                Anmelden
              </button>
            </div>
            <div className="neon-card bronze">
              <h2 className="symbol">3. Platz</h2>
              <div className="prize-list">
                <ul>
                  <li>Preis 1</li>
                  <li>Preis 2</li>
                  <li>Preis 3</li>
                </ul>
              </div>
              <button
                type="submit"
                className="submit-btn send"
                onClick={handleScroll}
              >
                Anmelden
              </button>
            </div>
          </section>
          <section className="contact-form">
            <h2>Anmelde Formular</h2>
            <form>
              <div className="form-columns">
                <div className="form-column">
                  <h3>Band-Information</h3>
                  <input
                    type="text"
                    name="artist-name"
                    placeholder="Artist-Name"
                    required
                  />
                  <input
                    type="text"
                    name="genre"
                    placeholder="Genre"
                    required
                  />
                  <input
                    type="text"
                    name="music-link"
                    placeholder="Music-Link"
                    required
                  />
                  <input
                    type="number"
                    name="members"
                    placeholder="#Members"
                    required
                  />
                </div>
                <div className="form-column">
                  <h3>Contact-Person</h3>
                  <input type="text" name="name" placeholder="Name" required />
                  <input type="email" name="mail" placeholder="Mail" required />
                  <input
                    type="message"
                    name="message"
                    placeholder="Nachricht"
                  />
                  <button type="submit" className="submit-btn send">
                    Senden
                  </button>
                </div>
              </div>
            </form>
          </section>
        </>
      )}
    </div>
  );
}

export default App;
